window.dataLayer = window.dataLayer || [];
function gtag() { dataLayer.push(arguments); }
gtag('js', new Date());

document.addEventListener('turbolinks:load', function (event) {
  if (typeof (gtag) == 'function') {
    gtag('config', GOOGLE_ANALYTICS_TAG, {
      'page_title': event.target.title,
      'page_path': event.data.url.replace(window.location.protocol + "//" + window.location.hostname, "")
    });

    if (window.performance) {
      if (!event.data.timing.visitStart) {
        // Initial page load
        const timeSincePageLoad = Math.round(window.performance.now());
        sendPageLoadTime(timeSincePageLoad)
      } else {
        // Turbolinks page load
        const timingData = event.data.timing;
        const timeSincePageLoad = Math.round(timingData.visitEnd - timingData.visitStart);
        sendPageLoadTime(timeSincePageLoad)
      }
    }
  }
});

function sendPageLoadTime(timeSincePageLoad) {
  gtag('event', 'timing_complete', {
    'name': 'load',
    'value': timeSincePageLoad
  });
}
